<app-navbar-one></app-navbar-one>

<div class="main-banner-area">
    <div class="home-sliders owl-carousel owl-theme">
        <div class="home-item item-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>Make Real-Life Connections With IT</h1>
                            <p>Your ability to stay connected is your better chance for optimal work engagement with featured softaskill tools and services for your business growth.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="assets/img/home-one/shape.png" alt="image">
                            <!-- <img src="assets/img/home-one/SST-HOME.JPg" class="banner-img" alt="image"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-item item-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>Software & Digital Development</h1>
                            <p>Optimize your business process with reliable software, and softaskill is your go-to vendor. We remain comitted to provide your IT business needs.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="assets/img/home-one/shape.png" alt="image">
                            <!-- <img src="assets/img/home-one/image-2.png" class="banner-img" alt="image"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-item item-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>PC, Tablets and other digital Devices</h1>
                            <p>Get your Laptops, tablets and more from us at affrodable rates. We offer direct sales and supplies to businesses.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="assets/img/home-one/shape.png" alt="image">
                            <!-- <img src="assets/img/home-one/image-3.png" class="banner-img" alt="image"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="features-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Core<span>Values</span></h2>
            <p>We keep close watch on our values to ensure we remain the IT consultant you can trust for optimal service delivery. Here are our core values:</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-blueprint"></i>
                    </div>
                    <h3>Adaptability</h3>
                    <p>Being proactive and resourceful based on the shifting demands is a key strategy. We adapt to changes with resilence to remain great in business.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-update"></i>
                    </div>
                    <h3>Commitment</h3>
                    <p>The willingness to put the time, effort, and energy into furthering the organization toward its goals,
                        with unwavering dedication to attend to daily tasks. </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-security"></i>
                    </div>
                    <h3>Teamwork</h3>
                    <p>Working Collaboratively with different skills and ideas to foster best solution driven from group analysis.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3>Innovation</h3>
                    <p>We are curious to explore solution to challenging problems, learning from both successes and failures with up-to-date service methodologies</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="creative-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="creative-content">
                    <h3>How to Generate <span>Creative</span> Ideas for <span>your</span> IT Business</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus do  umsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the industry. Lorem Ipsum has been the industry's.</p>
                </div>
                <div class="skill-bar" data-percentage="75%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Developement</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="45%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Design</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="80%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Marketing</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="70%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Support</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="90%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Review</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="creative-image">
                    <img src="assets/img/about/about.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=YymWhauqjoA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>We offer world class service in many areas of IT service provisioning to give your business and all-round service availability options</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>Helping your business grow with our available service option ranging from infrastructure provisioning to software and IT digital services delivery. </p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Software Development</h3>
                    <p>Developing enterprise application softwares, business websites and data repositories for your process when you need it...</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>Sell your business to the world via digital marketting channels in the shortest possible time t faster and at affordable prices.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Training</h3>
                    <p>Hire our expertise to equip your staff IT skills and grow your own business expertise through softaskill training programs.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>E-commerce Development</h3>
                    <p>The evolving digital space needs the face of your business. Increase our trading (buying and selling) chances from an e-commerce domain without stress.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Hardware Sales</h3>
                    <p>Buy reliable computer devices from our IT store at affordable prices. We sell laptops, iMac all-in-one desktop, tablets and other digital devices. </p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>

    <div class="services-shape">
        <img src="assets/img/cloud.png" alt="image">
    </div>
</section>

<section class="development-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="development-image">
                    <img src="assets/img/home-one/SST-HOME-WEB2.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="development-text">
                    <h3>Web & Mobile <span>Development</span></h3>
                    <div class="bar"></div>
                    <p>Web and mobile development is the peak of softaskill business activity we are most ready to offer: </p>
                </div>
                <div class="development-content">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>UI/UX Design</h3>
                    <p>Helping you prototype your business system user interface and user experience design.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-05dbcf">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Enterprise wide web application across all business sectors wether corporate and for individuals establishments. Witness paperless end to end process flow custom made softwares. </p>
                </div>
                <div class="development-content">
                    <div class="icon bg-fec66f">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Mobile Developement</h3>
                    <p>We leaverage on collaborative ties with our partners to provide you secured mobile apps that works. Lorem Ipsum has been the.</p>
                </div>
                <div class="development-content">
                    <div class="icon bg-66a6ff">
                        <i class="flaticon-devices"></i>
                    </div>
                    <h3>Responsive Design</h3>
                    <p>We build responsive website for your business shops, schools, medical facility, corporate firms and more.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="video-section">
    <div class="container">
        <div class="video-content">
            <a href="https://youtu.be/xgbJtffxmgY?si=aphV_w3ImJYF4YWb" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
        </div>
    </div>
</div> -->

<!-- <section class="choose-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why <span>Choose</span> Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3>Consulting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>Page Ranking</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>Location Targeting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/choose.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="projects-section pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Latest <span>Projects</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img1.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Update & Rebrand</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img2.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Consultancy</h3>
                        <span>Research and startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img3.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Digital Marketing</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img4.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img5.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Solutions</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img6.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Data Management</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/projects/img7.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>E-commerce Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="clients-section">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                    <span>Web Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                    <span>Agent Management</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3>
                    <span>Web Designer</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Latest</span> News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March 2023</span>
                        <h3><a routerLink="/blog-details">Great Tips To Earn More Money From Digital Industry</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 April 2023</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>30 July 2023</span>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->